/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/frontend/css/index.scss';
import { initI18nextWithResourceBundles } from './src/frontend/utils/i18next-helper';
import React, { useEffect } from 'react';
import config from './src/config';

// gatsby-browser.js
export const wrapPageElement = ({ element, props }) => {
  const context = props.pageContext;
  initI18nextWithResourceBundles(context.resourceBundles);
  window.localStorage.removeItem('i18nextLng');
  return element;
};

// A component that runs code after hydration
const HydrationListener = ({ children }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'locizify';
    script.src = 'https://unpkg.com/locizify@2.15.1/dist/umd/locizify.min.js';
    script.setAttribute('fallbackLng', config.locize.fallbackLng);
    script.setAttribute('projectId', config.locize.projectId);
    script.setAttribute('apiKey', config.locize.apiKey);
    script.setAttribute('version', config.locize.version);
    script.setAttribute('referenceLng', config.locize.referenceLng);
    script.setAttribute('keyAttr', 'i18next-key');
    script.setAttribute('ignoreWithoutKey', 'true');

    document.head.appendChild(script);
  }, []);

  return <>{children}</>;
};

export const wrapRootElement = ({ element }) => {
  return <HydrationListener>{element}</HydrationListener>;
};
